import { PageContent, JSXFactory, sitePix, exportedSiteData, render } from '@appzuka/rxnano';

import './components.scss';

const ComponentBlock = (c, even: boolean) => {
  render(
    <div class='component-block'>
      { () => sitePix(exportedSiteData.assetBase, c.image) }
      <div class={`component-block__content-wrapper ${even ? 'even' : 'odd'}`}>
        <h2>{c.title}</h2>
        <div class='component-block__desc-wrapper'>
          {c.text}
        </div>
      </div>


    </div>
  )

}

interface Component {
  title: string
  image: string
  text: JSX.Element
}

const componentList:Array<Component> = [
  {
    title: 'BuilDeck Collar',
    image: 'components/collar',
    text: <p>Our unique design allows complete integration with standard scaffolding.</p>
  },
  {
    title: 'BuilDeck Panel',
    image: 'components/panel',
    text: <p>Utilising standard scaffolding tubes and BuilDeck collars the fully adjustable floor panels can be easily installed to provide a safe, stable and incredibly strong working platform.</p>
  },
  {
    title: 'BuilDeck Hatch',
    image: 'components/hatch',
    text: <p>The BuilDeck ladder hatch provides quick and safe access.</p>
  },
  {
    title: 'BuilDeck Lapmat',
    image: 'components/lapmat',
    text: <p>Because not all houses are exactly the same dimensions, we at BuilDeck have created a system to meet all your requirements.</p>
  },
  // {
  //   title: 'BuilDeck SafeWire',
  //   image: 'components/safewire',
  //   text: <p>With the SafeWire and Tag system in place, it is easy to identify if the configuration has been tampered with.</p>
  // },
  // {
  //   title: 'BuilDeck Anti-Tamper',
  //   image: 'components/anti-tamper',
  //   text: <p>With the SafeWire and Tag system in place, it is easy to identify if the configuration has been tampered with.</p>
  // },
  {
    title: 'BuilDeck Jack',
    image: 'components/jack',
    text: <p>No Additional – BACK PROPPING Systems required, unlike existing crash deck converts.</p>
  },
  {
    title: 'BuilDeck Edge-Bar',
    image: 'components/edgebar',
    text: <p>The BuilDeck System can be Hand Railed and Toe Boarded to TG20:13 utilising standard scaffold components.</p>
  },
  {
    title: 'Compact Storage',
    image: 'components/stacking',
    text: <p>Compact stacking system allowing easy storage & loading</p>
  },
]

const ComponentGallery = () => {
  render(
    <div id='components' class='margin-container'>
      <h2>BuilDeck System Components</h2>
      <p>Buildeck is unique because it requires minimal extra Components above and beyond normal scaffold. It saves time and cost by allowing reuse of existing scaffold already on site while being much faster to assemble and convert.</p>
      <p class='no-mouse'>Tap on the components below to learn more.</p>
      { () => componentList.map((c, i) => ComponentBlock(c, i % 2 === 0)) }
    </div>
  );
}

export {
  ComponentGallery
}
