import { PageContent, JSXFactory, render } from '@appzuka/rxnano';
import { components as GallerySet } from 'site/components/gallery';
import { ComponentGallery } from 'site/components/components';

const galleryContent = () => {
  render(<>
    <h2>Gallery</h2>
    <p>Below are images of the BuildDeck system in use, demonstrating the flexibility of the BuilDeck system and its components on real sites.</p>
    <p>Touch a thumbnail to view a larger image.</p>
    { () => GallerySet('project1') }
    { ComponentGallery }
   </>);
}

let pageList = 
    [{
        pageLocation: 'gallery',
        image: {imageName: 'banners/system1', title: 'BuilDeck System'},
        pageTitle: 'BuilDeck System Gallery',
        content: galleryContent
    }];

const galleryPages : PageContent = {
    content: pageList,
    subMenu: []
}

export {
    galleryPages
};

