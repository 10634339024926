import { JSXFactory, sitePix, exportedSiteData, pixLoadedObservable, patch, render } from '@appzuka/rxnano';
import { first, map, takeWhile } from 'rxjs/operators';

const CommitInfo = require('virtual/commitinfo.js');

// FIXME: Force loaded pixlist if it is not loaded at start

import './gallery.scss';

const GallerySet = (pictureClass: string, nameFilter?: string) => {
  let compEl;

  const classRegex = new RegExp(`^${pictureClass}`)
  const nameRegex = new RegExp(`\/${nameFilter || ''}`)
  render(<div ref={(e) => { compEl = e; return e }} class="gallery-wrapper" />);
  // When the pixList is loaded, fill the div.
  let selected = -1;

  const fullrows:any[] = [];
  let currentRow = -1;
  let currentPix = -1;

  let pix:any[] =[];
  let allpix:any[] =[];

  const thumbclicked = (e,index) => {
    e.preventDefault();
    console.log('image '+index+' clicked');
    if ((currentRow >= 0) && (currentRow !== Math.floor(index/3))) {
      fullrows[currentRow].style.paddingTop = '0';
      fullrows[currentRow].style.paddingBottom = '0';
    }
    currentRow=Math.floor(index/3);
    patch(fullrows[Math.floor(index/3)], () => sitePix(exportedSiteData.assetBase, pix[index].file, {class: 'selected', mediaSizes: '100vw'}));
    fullrows[Math.floor(index/3)].style.paddingTop = '8px';
    fullrows[Math.floor(index/3)].style.paddingBottom = '66.6%';
    allpix[index].classList.add('shown');
    if (currentPix > 1) {
      allpix[currentPix].classList.remove('shown');
    }
    currentPix = index;
    // selected = index;
    // selectedPlace=selected-(selected % 3);
    // patchGallery(pix);
  }

  const patchGallery = (pixList) => {
    patch(compEl,
      () => pixList.map((p, i) => {
        if (i % 3 === 0) {
          render(<div class='fullrow' ref={(e) => { fullrows[Math.floor(i/3)] = e; return e }} id={`fullrow=${Math.floor(i/3)}`}></div>)
        }
        render(<div  ref={(e) => { allpix[i] = e; return e }} onclick={(e) => thumbclicked(e,i)} class={`sitepix-not-selected${i=== selected?' shown':''}`}>
          { () => sitePix(exportedSiteData.assetBase, p.file, {class: 'not-selected', mediaSizes: '30vw'}) }
        </div>)
      })
    );
  }

  pixLoadedObservable
    .pipe(
      first(pl => pl.length > 0), // Wait until pixlist is loaded. Cancel after first valid pixlist found

      map(pl => pl.filter(p => p.file.match(classRegex))), // Filter for the chosen image folder
      map(pl => pl.filter(p => p.file.match(nameRegex))), // Filter for the chosen image name
    )
    .subscribe(sublist => {
      pix = sublist;
      patchGallery(pix);
    });
}

export {
  GallerySet as components
};

