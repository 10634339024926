import { PageContent, JSXFactory, sitePix, exportedSiteData, render } from '@appzuka/rxnano';
import { SystemFeatures } from 'site/components/features';
import { KeyPointsBanner } from '../home/home';
import { ComponentGallery } from 'site/components/components';
import { Differentiators } from './differentiators';

import './system.scss';
import { getDefaultLibFileName } from 'typescript';

const TechSpecs = () => {
  render(
    <div id='techspecs' class='system-techspecs'>
      <div class='system-tecspecs__content margin-container'>

        <h2>Technical Specifications</h2>
        <ul>
          <li>1430kg/m<sup>2</sup> (14.3kN/m<sup>2</sup>) (UDL) load capacity</li>
        </ul>
      </div>
    </div>
  );
}

const Ordering = () => {
  render(
    <div id='ordering' class='system-order'>
      <div class='system-order__content margin-container'>

        <h2>How to Order</h2>
        <ul>
          <li>Available UK wide</li>
          <li>System can be supplied in any colour for custom branding</li>
        </ul>
      </div>
    </div>
  );
}

// const Differentiators = [
//   {
//     title: 'Flexible',
//     image: 'project1/dsc-0223.jpg',
//     points: [
//       'A Scaffold based Decking System.',
//       'Requires minimal Extra Components above and beyond Normal Scaffold.',
//       'Can be Totally Free Standing Up to 6m & used Tiered for Attic Trusses and Atriums.',
//       'The Buildeck System can be installed in a Single Lift up to 2m in Height, then varying Heights thereafter.',
//     ]
//   },
//   {
//     title: 'Fast to Install',
//     image: 'project1/dsc-0223.jpg',
//     points: [
//       'Requires only CISRS trained Scaffolders.',
//       'Utilising the Trades that are already on site.',
//       'Uses Standard Scaffold Handover Sheet once erected, assuring Compliancy to TG20:13. (If Required.)',
//       'Can be Assembled and Converted much faster, therefore cost savings to be had.',
//     ]
//   },
//   {
//     title: 'Versatile',
//     image: 'project1/dsc-0223.jpg',
//     points: [
//       'Easier to keep up with the Changing Programs on site, creating less delays for other trades.',
//       'Can be modified in-situ to replace Traditional Scaffold for Party Walls, and Gables.',
//       'Custom Fit Splayed Panels for Standard Home Types.',
//       'Alternatively, LAP Panels can be used in non-standard Plots. (Industry Accepted Standard).',
//     ]
//   },
//   {
//     title: 'Strong & Safe',
//     image: 'project1/dsc-0223.jpg',
//     points: [
//       'Standard BuilDeck panel is 1m x 1m which is above the minimum requirement for Men & Materials.',
//       'The BuilDeck System can be Hand Railed and Toe Boarded to TG20:13 utilising standard scaffold components.',
//       'Component and System Tested to BSEN 12811-1 2003, BSEN 13374:2013. BSEN 12811-2 2004.',
//       'Compact and light components of BuilDeck prevent damage to blockwork when stripping.',
//     ]
//   }
// ];

// const DifBlock = (d) => {
//   render(<div class='dif-block'>
//     <h4>{d.title}</h4>
//     { () => sitePix(exportedSiteData.assetBase, d.image) }
//     <ul>
//     { () => d.points.map(p => {
//       render(<li>{p}</li>);
//     })}
//     </ul>
//   </div>);
// }

// const Dif = () => {
//   render(<div class='dif-wrapper'>
//     { () =>   Differentiators.map(d => {
//     DifBlock(d);
//   })}
//   </div>)

// }



const systemContent = () => {
  render(<>

    { KeyPointsBanner }
  { SystemFeatures }

  {/* <div class='system-overview'>
  <div class='margin-container'>
  <h2>Overview</h2>
  <ul>
    <li>A Scaffold based decking system which uses predominantely scaffold tube and fittings and is erected by trained scaffolders.</li>
    <li>It can be adapted to provide back-propping to floors / joists / shuttering. </li>
    <li>BuilDeck has been designed and manufactured to provide the strongest, safe and cost effective decking solution to the industry.</li>
    <li>With a national network of distributors, we can supply BuilDeck throughout the UK.</li>
    <li>BuilDeck is an innovative, market-leading fall prevention work platform for the complete methods of construction methods. BuilDeck is a working platform not a crash deck and has the best same load capacity in the industry – capable of a 1430kg/m<sup>2</sup> loading.</li>
    <li>As internal fall protection, BuilDeck is designed to 'prevent the fall' rather than 'mitigate height and consequence'.</li>
  </ul>
  </div>
  </div> */}
  { Differentiators }


  { ComponentGallery }



  {/* { TechSpecs } */}



  </>);
}

let pageList = 
    [{
        pageLocation: 'system',
        image: {imageName: 'banners/system2', title: 'BuilDeck System'},
        pageTitle: 'BuilDeck System',
        content: systemContent,
        name: 'system'
    }];

const systemPages : PageContent = {
    content: pageList,
    subMenu: []
}

export {
    systemPages
};

