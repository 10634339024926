import { JSXFactory, render, sitePix, exportedSiteData, linkClick  } from '@appzuka/rxnano';
import {
  DesignIcon,
  FastIcon,
  StairsIcon,
  WeightIcon } from 'site/components/featureIcons';

import './differentiators.scss';
import topSVG from '../home/tophex.svg';

const DiffList = [
  {
    title: 'Flexible',
    icon: DesignIcon,
    list: [
      'A Scaffold based Decking System.',
      'Requires minimal Extra Components above and beyond Normal Scaffold.',
      'Can be Totally Free Standing Up to 6m & used Tiered for Attic Trusses and Atriums.',
      'The Buildeck System can be installed in a Single Lift up to 2m in Height, then varying Heights thereafter.',
    ]
  },
  {
    title: 'Fast to Install',
    icon: FastIcon,
    list: [
      'Can be assembled by BuilDeck trained and certified installers.',
      'Utilising the Trades that are already on site.',
      'Uses Standard Scaffold Handover Sheet once erected, assuring Compliancy to TG20:13. (If Required.)',
      'Can be Assembled and Converted much faster, therefore cost savings to be had.',
    ]
  },
  {
    title: 'Versatile',
    icon: StairsIcon,
    list: [
      'Easier to keep up with the Changing Programs on site, creating less delays for other trades.',
      'Can be modified in-situ to replace Traditional Scaffold for Party Walls, and Gables.',
      'Custom Fit Splayed Panels for Standard Home Types.',
      'Alternatively, LAP Panels can be used in non-standard Plots. (Industry Accepted Standard).',
    ]
  },
  {
    title: 'Strong & Safe',
    icon: WeightIcon,
    list: [
      'Standard BuilDeck panel is 1m x 1m which is above the minimum requirement for Men & Materials.',
      'The BuilDeck System can be Hand Railed and Toe Boarded to TG20:13 utilising standard scaffold components.',
      'Component and System Tested to BSEN 12811-1 2003, BSEN 13374:2013. BSEN 12811-2 2004.',
      'Compact and light components of BuilDeck prevent damage to blockwork when stripping.',
    ]
  }
];

const BannerBox = (title, icon, content) => {
  render(<div class='diff-banner__box'>
    <div class='diff-banner__box-heading'>
      { icon }
      <h2>{title}</h2>
    </div>

    <ul class='diff-banner__box-list'>
      { () => content.map(t => {
  const nodes = t.split('m2'); // Assumes at most 1 m2
  render(nodes.length > 1 ? <li>{nodes[0]}m<sup>2</sup>{nodes[1]}</li> : <li>{t}</li>)
  }) }
    </ul>

  </div>)
}


const Differentiators = () => {

  render(<div id='why' class="diff-banner">
    <div class='diff-banner__pattern'>
      <img class='tophex' src={topSVG} />
      <img class='bottomhex' src={topSVG} />
    </div>
    <h1 class='diff-banner__headline margin-container'>
       Why BuilDeck?
    </h1>
    <h2 class='diff-banner__mission margin-container'>
      BuilDeck out-performs both standard scaffold and other decking systems on Speed, Cost and Strength
    </h2>
    <div class='diff-banner__box-wrapper margin-container'>
      { () => DiffList.map( b => {
        BannerBox(b.title, b.icon, b.list)
      })}

    </div>
  </div>);
}

export {
  Differentiators
}
