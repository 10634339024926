import { PageContent, JSXFactory, sitePix, exportedSiteData, render } from '@appzuka/rxnano';

import {
  DesignIcon,
  PatentIcon,
  PlatformIcon,
  StairsIcon,
  CompactIcon,
  ClipboardIcon,
  FastIcon,
  WeightIcon } from 'site/components/featureIcons';

import './features.scss';

const backgroundImages = [
  'project1/dsc-0228.jpg',
  'project1/dsc-0198.jpg',
  'project1/dsc-0223.jpg',
  'project1/dsc-0175.jpg',
  'project1/dsc-0181.jpg',
  'project1/dsc-0198.jpg',
  'project1/dsc-0214.jpg',
  'project1/dsc-0227.jpg'
]


const FeatureBlock = (title, icon, list, i) => {
  render(<>
  <div id='features' class={`feature-block ${i % 2 === 0 ? 'even' : 'odd'}`}>
    <div class='feature-block__panel'>
      { () => sitePix(exportedSiteData.assetBase, backgroundImages[i % backgroundImages.length], { class: 'feature-mask' }) }
      <div class='feature-block__mask'></div>
      <h2>{title}</h2>
      { icon }
    </div>
    <div class='feature-block__content-wrapper'>
      <div class='feature-block__content-spacer'/>
      <div class='feature-block__content'>
        { () => list.map(t => {
          const nodes = t.split('m2'); // Assumes at most 1 m2
          render(nodes.length > 1 ? <p>{nodes[0]}m<sup>2</sup>{nodes[1]}</p> : <p>{t}</p>)
          })}
      </div>
    </div>
  </div>
  </>);
}

const featureList = [
  {
    title: 'Strongest Available',
    icon: WeightIcon,
    list: [
      '14.3kN/m2 load capacity',
      'Independently Tested',
      'As strong as standard scaffolding',
      'The strongest decking solution available',
    ]
  },
  {
    title: 'Patented',
    icon: PatentIcon,
    list: [
      'Patent Granted - GB2518159',
      'The only patented scaffold-based decking solution available',
      'Fully integrated with standard scaffold',
      'Can be assembled by BuilDeck trained and certified installers'
    ]
  },
  {
    title: 'Versatile',
    icon: StairsIcon,
    list: [
      'Provides a platform at any height',
      'Can be made freestanding',
      'Ladder hatch units available',
      'Can be adapted to provide back-propping',
      'Can be hand-railed & toe-boarded to TG20:13',
    ]
  },
  {
    title: 'Saves Time & Labour',
    icon: FastIcon,
    list: [
      'Fast installation & dismantling',
      'Quicker to install than a birdcage scaffolding system',
      '2 men can erect 80m2 per hour',
    ]
  },
  {
    title: 'Tested',
    icon: ClipboardIcon,
    list: [
      'BuilDeck has been independently tested',
      'Complies with working at height regulations',
      '(BS EN12811-1 part 3)',
    ]
  },
  {
    title: 'Safe Platform at any Height',
    icon: PlatformIcon,
    list: [
      'BuilDeck surface provides firm, confident footing',
      'Suitable in all weathers for all methods of construction',
      'The system can be installed from underneath eliminating the need to work at height',
    ]
  },
  {
    title: 'Long Lasting & Compact',
    icon: CompactIcon,
    list: [
      'Compact and Lightweight',
      'Can be stored on pallets',
      'Forklift Friendly',
      'Can be stored outside',
      'Made from 99% Recyclable Materials',
    ]
  },
  {
    title: 'Bespoke Design Available',
    icon: DesignIcon,
    list: [
      'Bespoke design service',
      'Fit any shape or size',
      'Custom branding available',
      'Available to buy UK wide',
    ]
  }
]

const SystemFeatures = () => {
  render(
    <div id='features' class='margin-container'>
      <h2>BuilDeck System Features & Benefits</h2>
      { () => featureList.map((f, i) => FeatureBlock(f.title, f.icon, f.list, i)) }
    </div>
  );
}

export {
  SystemFeatures
}
