import { JSXFactory, render } from '@appzuka/rxnano';
import './logo.scss';
import salogoSVGRAW from './salogo.svg';
export const salogoSVG = () => salogoSVGRAW('salogo-dark');
export const salogoWhiteSVG = () => salogoSVGRAW('salogo-light');

const BuilDeckLogo = (logoClass:string) => {

  render(<svg class={'buildeck-logo '+logoClass} viewBox="0 0 424.62 73.85">
    <rect class="logo-buil-rect" style="fill:#223a89" width="197.23" height="73.85"/>
    <rect class="logo-deck-rect" style="fill:#dd0881" x="197.23" width="233.03" height="73.85"/>
    <path class="logo-letter" d="M39.33,11.92c3.31,0,8.35.07,12,3,2,1.66,4,4.68,4,9.58a10.38,10.38,0,0,1-3.09,7.78,10.43,10.43,0,0,1-5.12,3v.29a14.22,14.22,0,0,1,7.13,3.53c1.88,1.8,3.6,4.75,3.6,9.43,0,5.62-2.59,8.71-4.75,10.3-4,3-10.22,3.45-14,3.45H10.81V11.92ZM26.51,31.36h8.07c1.29,0,2.88-.07,3.88-1a3.69,3.69,0,0,0,1.16-3,3.7,3.7,0,0,0-1.3-3.1,7.38,7.38,0,0,0-4-.93H26.51Zm0,19.59H36a6.06,6.06,0,0,0,4.1-1.08,4.5,4.5,0,0,0,1.37-3.53,4.16,4.16,0,0,0-1.44-3.53,6.89,6.89,0,0,0-4.11-1H26.51Z"/>
    <path class="logo-letter" d="M113.49,43.46c0,4.82-.36,9.72-4.9,14.33s-11,5.9-18.36,5.9-14-1.3-18.5-5.9c-4.32-4.4-4.9-9.29-4.9-14.4V11.92H82.6V42.74c0,2,.29,4.61,1.94,6.33a7.48,7.48,0,0,0,5.62,2,7.48,7.48,0,0,0,5.62-2c1.65-1.72,1.94-4.32,1.94-6.33V11.92h15.77Z"/>
    <path class="logo-letter" d="M141.42,11.92v50.4h-15.7V11.92Z"/>
    <path class="logo-letter" d="M191.17,62.32H153.66V11.92h15.7V49h21.81Z"/>
    <path class="logo-letter" d="M227.25,11.92c5.9,0,12.24.65,17.49,5.62,4.83,4.53,7.27,12.24,7.27,20.3,0,11.38-5,17.07-7.34,19.23-5.54,5-11.52,5.25-17.42,5.25h-24.2V11.92Zm-8.5,36.87h5.61c2.31,0,5.55-.15,7.93-2.38,2.59-2.38,3-6.41,3-9.29,0-1.87-.07-6.77-2.74-9.43-2.37-2.38-5.9-2.52-8.28-2.52h-5.54Z"/>
    <path class="logo-letter" d="M298.74,30.93V42.81H276.35V49h26.71V62.32H260.65V11.92h41.84V25.24H276.35v5.69Z"/>
    <path class="logo-letter" d="M359.51,43.75a23.21,23.21,0,0,1-6.26,14.18c-5.62,5.55-14,5.83-18.65,5.83-5.19,0-12.53-.5-18.15-5.76-3.53-3.31-7.27-9.79-7.27-20.38,0-9.5,3.09-16.12,7.49-20.37,5.76-5.62,13-6.63,18.93-6.63,8.28,0,14,2.38,17.65,6.12a22.5,22.5,0,0,1,5.83,13.4h-15.2a7.65,7.65,0,0,0-2.37-4.54,8.52,8.52,0,0,0-5.4-1.58,9.29,9.29,0,0,0-7,2.52c-2.88,2.88-3.24,7.56-3.24,10.65,0,3.24.44,7.42,2.81,10.23,2.23,2.66,4.83,2.88,7,2.88a8.35,8.35,0,0,0,6-1.95,7.33,7.33,0,0,0,2.45-4.6Z"/>
    <path class="logo-letter" d="M398.6,11.92H417L396.88,32.87l21.24,29.45H399.18L386.65,43.17l-3.89,4.1v15H367.07V11.92h15.69V28.41Z"/>
  </svg>);

}

export {
  BuilDeckLogo
}
