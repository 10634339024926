import { PageContent, JSXFactory, sitePix, exportedSiteData, render } from '@appzuka/rxnano';

import './timeline.scss';

const backgroundImages = [
  'project1/dsc-0228.jpg',
  'project1/dsc-0198.jpg',
  'project1/dsc-0223.jpg',
  'project1/dsc-0181.jpg',
  'project1/dsc-0198.jpg',
  'project1/dsc-0214.jpg',
  'project1/dsc-0227.jpg'
]


const TimelineBlock = (title, list, i) => {
  render(<>
  <div id='timelines' class={`timeline-block ${i % 2 === 0 ? 'even' : 'odd'}`}>
    <div class='timeline-block__panel'>
      { () => sitePix(exportedSiteData.assetBase, backgroundImages[i % backgroundImages.length], { class: 'timeline-mask' }) }
      <div class='timeline-block__mask'></div>
      <h2>{title}</h2>
    </div>
    <div class='timeline-block__content-wrapper'>
      <div class='timeline-block__content-spacer'/>
      <div class='timeline-block__content'>
        { () => list.map(t => {
          const nodes = t.split('m2'); // Assumes at most 1 m2
          render(nodes.length > 1 ? <p>{nodes[0]}m<sup>2</sup>{nodes[1]}</p> : <p>{t}</p>)
          })}
      </div>
    </div>
  </div>
  </>);
}

const timelineList = [
  {
    title: '2021',
    list: [
      'Patent Granted - GB2518159',
      'BuilDeck becomes the only patented scaffold based decking system'
    ]
  },
  {
    title: '2020',
    list: [
      'BuilDeck Scaffold Systems Ltd incorporated'
    ]
  },
  {
    title: '2013',
    list: [
      'Patent applied for BuildDeck',
    ]
  },
  {
    title: '2011',
    list: [
      'BuilDeck developed by Fred Beales',
    ]
  },
  {
    title: '2007',
    list: [
      'Beales Scaffolding Ltd incorporated'
    ]
  },
  {
    title: '1980',
    list: [
      'Beales Scaffolding started in Reading.  Fred started with work in the business from a young age, taking his first wage at 16.',
    ]
  }
]

const SystemTimeline = () => {
  render(
    <div id='timelines' class='margin-container'>
      <h2>BuilDeck Timeline</h2>
      { () => timelineList.map((f, i) => TimelineBlock(f.title, f.list, i)) }
    </div>
  );
}

export {
  SystemTimeline
}
