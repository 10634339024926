import { JSXFactory, render, sitePix,exportedSiteData, linkClick  } from '@appzuka/rxnano';
import { SiteData } from 'site/siteContent';
import { PageContent, completeCallback } from '@appzuka/rxnano';
import { BuilDeckLogo } from 'site/components/logo';
import { accreditations } from 'site/components/accreditations';
import { ComponentGallery } from 'site/components/components';
import { salogoSVG } from 'site/components/logo';

  import {
    ClipboardIcon,
    FastIcon,
    WeightIcon } from 'site/components/featureIcons';

import './home.scss';
import topSVG from './tophex.svg';

const bannerBlocks = [
  {
    title: 'Saves Time & Labour',
    icon: FastIcon,
    list: [
      'Quicker to install than a birdcage scaffolding system',
      '2 men can install up to 80m2 per hour',
      'Can be assembled by a BuilDeck trained and certified installer',
      'Fully integrated with standard scaffold'
    ]
  },
  {
    title: 'Strong',
    icon: WeightIcon,
    list: [
      'Independently Tested',
      'As strong as standard scaffold',
      'Can be free standing up to 6m',
      'Provides back-propping',
      'The strongest decking solution available'
    ]
  },
  {
    title: 'Versatile',
    icon: ClipboardIcon,
    list: [
      'Create a platform at any height',
      'Can be made freestanding',
      'Suitable for all forms of construction',
      'Bespoke Design Service to fit any shape & size',
      'Compact, Lightweight & Long-lasting'
    ]
  }
]

const BannerBox = (title, icon, content) => {
  render(<div class='main-home-banner__box'>
    <div class='main-home-banner__box-heading'>
      { icon }
      <h2>{title}</h2>
    </div>

    <ul class='main-home-banner__box-list'>
      { () => content.map(t => {
  const nodes = t.split('m2'); // Assumes at most 1 m2
  render(nodes.length > 1 ? <li>{nodes[0]}m<sup>2</sup>{nodes[1]}</li> : <li>{t}</li>)
  }) }
    </ul>

  </div>)
}

const Accreditations = () => {
  render(<div class="accreditations-home-banner margin-container">
<h2>Accreditations</h2>
{ accreditations }
  </div>)
}

const About = () => {
  render(
    <a href='/about' onclick={e => linkClick(e, '/about')} class="about-home-banner">
        <div class='about-home-banner__pattern'>
      <img class='tophex' src={topSVG} />
      <img class='bottomhex' src={topSVG} />
    </div>
    <div class="about-home-banner__content margin-container">
<h2>About BuilDeck</h2>
<div class='about-home-banner__wrapper'>
{ () => sitePix(exportedSiteData.assetBase, 'people/fred-beales') }
<div>
<p>BuilDeck CEO Fred Beales heads a family-run firm making construction safer in Berkshire and across the UK for over 40 years.</p>
<p>Fred knows the business and how to reduce costs while increasing safety. His experience is valued across the industry and country at all levels.</p>
<p>Click here for further details about BuilDeck.</p>
</div>
</div>
  </div>
  </a>
  )
}

const Association = () => {
  render(<div class='margin-container'>
    <h2 style="font-size: 2rem">Industry Association</h2>
    <div class='about-as-wrapper'>
      <div class='about-as-logo'>
        {salogoSVG}
      </div>
      <div class='about-as-content'>
        <p>BuilDeck are proud to be members of the Scaffolding Association, the largest trade organisation in the UK scaffolding and access sector, campaigning to raise standards of safety, quality and workforce skills.</p>
        <p>For further information please visit: <a  target='_blank' rel='noopener noreferrer' href='https://scaffolding-association.org/'>scaffolding-association.org</a></p>
      </div>
    </div>
  </div>

  )
}

const Association2 = () => {
  render(<div class='margin-container'>
    <h2 style="font-size: 2rem">Industry Associations</h2>
    <p>BuilDeck are proud to announce that we have been successful in securing FASET membership.</p>
    <p>One benefit of FASET membership is recognition - we are proud to be a member and dedicated to advancing working at height safety in the industry. Membership signifies that BuilDeck works to the extremely high standards FASET operate at.</p>
    <div class='about-as2-wrapper'>
      <div class='about-as2-logo'>
        {salogoSVG}
      </div>
      <div class='about-as2-logo'>
        <img  src='/images/smas.png' />
      </div>
      <div class='about-as2-logo'>
        <img  src='/images/faset.png' />
      </div>
      <div class='about-as2-logo'>
        <img  src='/images/ssip.png' />
      </div>
      {/* <div class='about-as-content'>
        <p>BuilDeck are proud to be members of the Scaffolding Association, the largest trade organisation in the UK scaffolding and access sector, campaigning to raise standards of safety, quality and workforce skills.</p>
        <p>For further information please visit: <a  target='_blank' rel='noopener noreferrer' href='https://scaffolding-association.org/'>scaffolding-association.org</a></p>
      </div> */}
    </div>
  </div>

  )
}

const ShowCase = () => {
  render(<a href='/gallery' onclick={e => linkClick(e, '/gallery')} class="showcase-home-banner margin-container">
<h2>BuilDeck Up and Running</h2>
<div class='showcase-home-banner__wrapper'>
{ () => sitePix(exportedSiteData.assetBase, 'project1/dsc-0179.jpg', {mediaSizes: '50vw'}) }
<div class='showcase-home-banner__content'>
<p>BuilDeck is making construction sites safer across the UK today.</p>
<p>Click here to see the system in use.</p>
</div>

</div>
  </a>)
}

const Flyer = () => {
  render(<div class="flyer-home-banner margin-container">
<h2>Further Information</h2>
<a href='https://appzuka-web.s3.eu-west-2.amazonaws.com/buildeck/buildeck2310.pdf' target='_blank' class='flyer-home-banner__wrapper'>
{ () => sitePix(exportedSiteData.assetBase, 'site/flyer2310', {mediaSizes: '175px'}) }
<div class='flyer-home-banner__content'>
<p>For further information on BuilDeck's innovative fall prevention solution please <a href='/contact' onclick={e => linkClick(e, '/contact')}>Contact Us</a> or  click here to download the information sheet.</p>
</div>

</a>
  </div>)
}

const Statement = () => {
  render(
  <div class="statement-home-banner">
  <div class="margin-container">
<h2>Reduce Project Costs with the Market Leading Fall Prevention Solution
</h2>
  </div>
  </div>
  )
}

const KeyPointsBanner = () => {
  render(
  <div  id='overview' class="key-home-banner">
  <div class="margin-container">
<p>The BuilDeck system uses standard scaffold tube and fittings to provide a safe, load-bearing platform at any height.</p>
<p>Can be designed any shape - custom panels to suit.</p>
  <p>A patented system (GB2518159) with a load capacity of 1430kg/m<sup>2</sup> / 14.3kN/m<sup>2</sup> (UDL). <span>The strongest in the industry.</span></p>
  <p> Our system can be delivered anywhere in the UK.
</p>
  </div>
  </div>
  )
}

const Banner1 = () => {





  render(<div class="main-home-banner">
    <div class='main-home-banner__pattern'>
      <img class='tophex' src={topSVG} />
      <img class='bottomhex' src={topSVG} />
    </div>
    <h1 class='main-home-banner__headline margin-container'>
       Scaffold-Based Decking System
    </h1>
    <h2 class='main-home-banner__mission margin-container'>
    A patented system with a load capacity of 1430kg/m<sup>2</sup> (14.3kN/m<sup>2</sup>) (UDL). The strongest in the industry.
    </h2>
    <div class='main-home-banner__box-wrapper margin-container'>
      { () => bannerBlocks.map( b => {
        BannerBox(b.title, b.icon, b.list)
      })}

    </div>
    <div class='button-container margin-container'>
      <a class='learn-more white' onclick={e => {linkClick(e, '/system')}} href='/system'>Learn More</a>
    </div>
  </div>);
}

const homePages : PageContent = {
    content: [
        {
            pageLocation: '',
            image: { imageName: 'banners/system2', title: () => {
              BuilDeckLogo('logo-large');
              salogoSVG();
            },
              variantArray: [['mobile', 768], ['home', 1200], ['wide']] },
            pageTitle: 'Home',
            content: () => {
              Banner1();
              KeyPointsBanner();
              ComponentGallery();
              About();
              Association2();
              ShowCase();
              Statement();
              Flyer();
              // Accreditations();
              completeCallback('home');
            },
            name: 'home'
        }
    ],
    subMenu: []
};

export {
    homePages,
    KeyPointsBanner,
    Association2
};

