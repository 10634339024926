import { JSXFactory, sitePix, exportedSiteData, pixLoadedObservable, patch, render } from '@appzuka/rxnano';
import { first, map, takeWhile } from 'rxjs/operators';

const CommitInfo = require('virtual/commitinfo.js');

// FIXME: Force loaded pixlist if it is not loaded at start

import './accreditations.scss';

let accredEl;

const accreditations = () => {
  render(<div ref={(e) => { accredEl = e; return e }} id="accreditations-wrapper" />);
  // When the pixList is loaded, fill the div.
  pixLoadedObservable
    .pipe(
      first(pl => pl.length > 0), // Wait until pixlist is loaded. Cancel after first valid pixlist found
      map(pl => pl.filter(p => p.file.match(/^accreditations/))), // Filter for the chosen image
    )
    .subscribe(pix => {
      patch(accredEl,
        () => pix.map(p => { sitePix(exportedSiteData.assetBase, p.file) })
      );
    });
}

export {
  accreditations
};

