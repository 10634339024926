import { PageContent, completeCallback, sitePix, exportedSiteData, render, JSXFactory } from '@appzuka/rxnano';
import { SystemTimeline } from 'site/components/timeline';
import { accreditations } from 'site/components/accreditations';
import { salogoSVG, salogoWhiteSVG } from 'site/components/logo';
import { Association2 } from 'site/pages/home/home';

import './about.scss';

const AboutBuilDeck = () => {
  render(<div class='about-buildeck'>
    <h2>About BuilDeck</h2>
    <p>BuildDeck & Beales Scaffolding is a family run firm making construction safer in Berkshire and across the UK for over 40 years. Fred worked in his father's scaffolding firm whilst he was still at school and was rewarded with wages from age 16.  Today they have grown the team to 80 and Fred has bought the business from his father.</p>
    <p>In 2011 Fred began working with stand-alone decking systems when they
      were specified for jobs and he soon realised their pros and cons and the
      additional investment costs. His vision was to design a system based on
      scaffold tubes with greater strength and flexibility than other decking systems
      and Buildeck was born!</p>
    <p>Fred knows the business and is trusted at all levels within the industry.</p>
  </div>);
}

interface Employee {
  name: string
  title: string
  image: string
  bio: JSX.Element
}

const aboutBuilDeck = () => {
  render(<><p>Beales Scaffolding is a family run firm making construction safer in Berkshire and across the UK for over 40 years. Fred worked in his father's scaffolding firm whilst he was still at school and was rewarded with wages from age 16.  Today they have grown the team to 80 and Fred has bought the business from his father.</p>
    <p>Fred is passionate about the business and is trusted at all levels within the industry. He knows how good his system is and loves to talk to people who understand
      scaffolding. Learn all about it here and if you want to speak to Fred he would
      be very happy to return your call.</p>
  </>)
}

const Development = () => {
  render(<div class='about-development'>
    <h2>BuilDeck Development</h2>
    <div class='about-development__wrapper'>
      <div class='about-development__content'>
        <p>In 2011 Fred began working with stand-alone decking systems when they
          were specified for jobs and he soon realised their pros and cons and the
          additional investment costs.</p>
        <p>His vision was to design a system based on
          scaffold tubes with greater strength and flexibility than other decking systems
          and Buildeck was born!</p>
      </div>
      <div class='about-development__image-wrapper'>
        {() => [
          {
            file: 'site/prototype1',
            date: '5th August 2011'
          },
          {
            file: 'site/prototype2',
            date: '5th August 2011'
          }
        ].map(di => {
          render(<div class='single-image'>
            {() => sitePix(exportedSiteData.assetBase, di.file, { mediaSizes: '50vw' })}
            <p class='image-date'>{di.date}</p>
          </div>)
        })}
      </div>
    </div>
    { Association2 }
    {/* <h2>Industry Association</h2>
    <div class='about-as-wrapper'>
      <div class='about-as-logo'>
        {salogoSVG}
      </div>
      <div class='about-as-content'>
        <p>BuilDeck are proud to be members of the Scaffolding Association, the largest trade organisation in the UK scaffolding and access sector, campaigning to raise standards of safety, quality and workforce skills.</p>
        <p>For further information please visit: <a  target='_blank' rel='noopener noreferrer' href='https://scaffolding-association.org/'>scaffolding-association.org</a></p>
      </div>
    </div> */}
  </div>
  )

}


const employees = [
  {
    name: 'Fred Beales',
    title: 'CEO',
    image: 'people/fred-beales',
    bio: <><p>Fred Beales worked for his Father's scaffolding firm whilst he was still at school and at 16 he was rewarded with wages as he learnt the business literally from the bottom up. At the beginning they were a team of 8 and as he took a bigger role in the business they have grown to a team of 80. Today, he has bought the business from his Father and his son Sam is following in his footsteps.</p>
      <p>Fred knows how good his system is and loves to talk to people who understand scaffolding. Learn all about it here and if you want to speak to Fred he would be very happy to return your call.</p></>
  },
  // {
  //     name: 'Roland Denning',
  //     title: 'Director',
  //     image: 'people/avatar',
  //     bio: <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus non ante quis nulla commodo dapibus sed id elit. Aliquam vestibulum placerat ipsum, vel lacinia diam laoreet malesuada. Sed pellentesque rutrum arcu vel euismod. Aliquam porta rhoncus facilisis. In lobortis euismod ipsum sed tristique.</p>
  // },
  // {
  //   name: 'Colin Hardiman',
  //   title: 'Director',
  //   image: 'people/colin-hardiman',
  //   bio: <p>Colin is a highly proficient and commercially astute consultant with extensive experience across the construction sector. He advises a wide range of clients on contractual, commercial and project management briefs; which encompass commercial and contractual advice, bid and tender management, pre and post contract planning, budgeting and management.</p>
  // },
  // {
  //     name: 'Rob Nash',
  //     title: 'Director',
  //     image: 'people/avatar',
  //     bio: <p>Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Etiam elementum diam diam. Sed vel ante nec ipsum mollis cursus. Aenean sem ante, laoreet eget ipsum ut, viverra convallis odio. Proin eget mauris non magna auctor tempor.</p>
  // },
  // {
  //     name: 'Mike Lohans',
  //     title: 'Director',
  //     image: 'people/avatar',
  //     bio: <p>Mauris molestie consequat tellus, in ultricies leo elementum id. Curabitur vel dui neque. Vestibulum dignissim sapien vel commodo tristique. Ut molestie in erat placerat interdum. Proin neque sapien, congue sed facilisis quis, tincidunt vitae ligula. Ut convallis nibh sed purus tempus, id commodo nisi porttitor.</p>
  // }

]

const Staff = () => {
  render(<div class='staff'>
    <h2>About BuilDeck</h2>
    {() => employees.map((e, i) => {
      render(<div class='staff__employee-wrapper'>
        {/* <div class='staff__imagebox'>
            { () => sitePix(exportedSiteData.assetBase, e.image) }
            <div class={`staff__content-wrapper ${i % 2 === 0 ? 'even' : 'odd'}`}>
              <h2>{e.name}</h2>
              <h4>{e.title}</h4>
            </div>
          </div> */}
        <div class='staff__bio'>
          {aboutBuilDeck}
        </div>
      </div>)
    })}

  </div>);

}

const Accreditations = () => {
  render(<>
    <h2>Accreditations</h2>
    {accreditations}
  </>)
}

const aboutPages: PageContent = {
  content: [
    {
      pageLocation: 'about',
      image: { imageName: 'banners/system', title: 'About' },
      pageTitle: 'About',
      content: () => {
        Staff();
        Development();
        SystemTimeline();
        completeCallback('about');
      },
      name: 'about'
    },
    // {
    //     pageLocation: 'accreditations',
    //     image: () => {},
    //     pageTitle: 'Accreditions',
    //     content: Accreditations,
    //     name: 'accreditations'
    // }
  ],
  subMenu: []

}

export {
  aboutPages
};

