import {
    elementOpen,
    elementVoid,
    elementClose,
    text
} from 'incremental-dom';

import { ContactType, rxnanoIcons, linkClick, JSXFactory, render } from '@appzuka/rxnano';
import { BuilDeckLogo } from 'site/components/logo';
import { salogoSVG, salogoWhiteSVG } from 'site/components/logo';

const CommitInfo = require('virtual/commitinfo.js');

import { ISiteData } from "rxnanoModels";

interface FooterContactLine {
    type: ContactType,
    icon: any,
    label: string;
    target: string
}

type FooterContactArray  = FooterContactLine[];

export const MapsAPIKey = 'AIzaSyAWGm7lJKidOYOZwusjGXLXgJApnXf5XQ8';

export const FooterContacts : FooterContactArray = [
    {type: ContactType.Link, icon: rxnanoIcons.worldIcon, label: 'www.buildeck.co.uk', target: '/'},
    {type: ContactType.Mail, icon: rxnanoIcons.emailIcon, label: 'info@buildeck.co.uk', target: 'info@buildeck.co.uk'},
    {type: ContactType.Line, icon: rxnanoIcons.phoneIcon, label: '+44 (0) 1635 914870', target: ''},
];

export const FooterAddress : FooterContactArray = [
    {type: ContactType.Link, icon: rxnanoIcons.locationIcon, label: 'Unit 6 Pipers Lane Industrial Estate', target: '/contact'},
    { type: ContactType.Line, icon: undefined, label: 'Pipers Lane, Thatcham, Berkshire', target: '' },
    { type: ContactType.Line, icon: undefined, label: 'RG19 4NA, UK', target: '' },
    {type: ContactType.Link, icon: rxnanoIcons.mailIcon, label: 'Contact Us', target: '/contact'}
];

export const HeaderSiteLogo = () => {
  render(<div class='full-logo'>
    { () => BuilDeckLogo('logo-small') }
    <p>Scaffold Systems</p>
  </div>);
}

export const FooterSiteLogo = () => {
    BuilDeckLogo('logo-small');
    salogoWhiteSVG();

}

interface HeadMenuItem {
    menuText: string
    link: string
    priority: number
}

type HeadMenuArray = HeadMenuItem[];
type DropMenuArray = HeadMenuArray[];

const defineMenuItem = (item:string, priority: number, link?:string) : HeadMenuItem  =>
  ({menuText: item, priority: priority, link: link===undefined?'/'+item.toLowerCase():link});

export const PageLabels = {
    'about': 'about us'
};

export const HeadMenuContent : HeadMenuArray = [
    defineMenuItem('About', 4),
    defineMenuItem('System', 6),
    defineMenuItem('Gallery', 2),
    defineMenuItem('Contact', 8)
];

export const TopMenuContent = HeadMenuContent;

const DropMenuContentArray: DropMenuArray = [
    [
        defineMenuItem('About Us', 1, '/about'),
        defineMenuItem('Home', 1, '/'),
        // defineMenuItem('Accreditations', 1, '/accreditations'),
        defineMenuItem('Contact', 1),
        defineMenuItem('Privacy', 1, '/policies/privacy'),
        defineMenuItem('Terms', 1, '/policies/terms')
    ],
    [
        defineMenuItem('System', 1),
        defineMenuItem('Overview', 1,  '/system#overview'),
        defineMenuItem('Features', 1,  '/system#features'),
        defineMenuItem('Why BuilDeck', 1, '/system#why'),
        defineMenuItem('Components', 1,  '/system#components'),
    ],
    [
        defineMenuItem('Gallery', 1),
        defineMenuItem('BuilDeck On-Site', 1, '/gallery'),
        defineMenuItem('Components', 1, '/gallery'),
    ]
];

type vf = (string?) => void;

export const DropMenuContent: DropMenuArray | vf = (currentLocation?: string) => {
    DropMenuContentArray.map((c, j) => {
        elementOpen('div', null, null, 'class', 'hovermenucolumn');
        elementOpen('ul');
            c.map(({menuText, link}, i) => {
                let classList, finalMenuText;
                if (menuText[0] === '*') {
                    classList = 'other-link';
                    finalMenuText = menuText.slice(1);
                } else {
                    classList = currentLocation===link?'active-link':'other-link';
                    finalMenuText = menuText;
                }
                elementOpen('li');
                elementOpen('a', `hmc-${link}-${i}`, null, 'href', link,
                'class', classList,
                'onclick', (event) => {
                    linkClick(event, link);
                });
                if (finalMenuText.length === 0) {
                    text(' ');
                } else {
                    text(finalMenuText);
                }

                elementClose('a');
                elementClose('li');
            });
        elementClose('ul');
    elementClose('div');
    });
}

type SideMenuArray = Array<Array<Array<string>>>;

const SideMenuContentArray: SideMenuArray = [
    [
        ['About Us', '/about'],
        ['Home', '/'],
        // ['Accreditations', '/accreditations'],
        ['Contact', '/contact'],
        ['Privacy', '/policies/privacy'],
        ['Terms', '/policies/terms']
    ],
    [
        ['System', '/system'],
        ['Why BuilDeck', '/system#why'],
        ['Components', '/system#components'],
        ['Features', '/system#features'],
    ],
    [
        ['Gallery', '/gallery'],
        ['BuilDeck On-Site', '/gallery'],
        ['Components', '/gallery'],
    ]
];

// TODO: If the link us is the same the menu does not close
export const SideMenuContent: SideMenuArray | vf = (currentLocation?: string) => {
    SideMenuContentArray.map((menuColumn, j) => {
        elementOpen('div', null, null, 'class', 'sidemenucolumn');
        elementOpen('ul');
        menuColumn.map(([menuText, link], i) => {
                elementOpen('li');
                elementOpen('a', `smc-${link}-${i}`, null, 'href', link,
                'class', ((currentLocation===link) && ((j===0) || (i===0)))?'active-link':'other-link',
                'onclick', (event) => {
                    linkClick(event, link);
                });
                    text(menuText);
                elementClose('a');
                elementClose('li');
            });
        elementClose('ul');
    elementClose('div');
    });
}

interface ContactPageItem {
    icon: any,
    label: string,
    linkText: string,
    link?: string
}

type ContactPageArray = ContactPageItem[];

const defineContactPageItem = (icon: any, label:string, linkText:string, link?:string) : ContactPageItem  =>
  ({icon: icon, label: label, linkText: linkText, link: link});


const siteAddress = {
    mapinfo: {
        latitude: 51.39550,
        longitude: -1.24027,
        zoom: 8,
        title: 'BuilDeck'
    },
    details: [
        defineContactPageItem(rxnanoIcons.locationIcon, 'Address', 'Unit 6 Pipers Lane Industrial Estate, Pipers Lane, Thatcham, Berkshire, RG19 4NA, UK'),
        defineContactPageItem(rxnanoIcons.phoneIcon, 'Phone', '+44 (0) 1635 914870'),
        defineContactPageItem(rxnanoIcons.worldIcon, 'Web', 'www.buildeck.co.uk', '/'),
        defineContactPageItem(rxnanoIcons.emailIcon, 'eMail', 'info@buildeck.co.uk', 'mailto:info@buildeck.co.uk'),
    ]
}

export const ContactAddressList = [
    siteAddress
]

export const SiteData:ISiteData = {
    ga: window.location.hostname==='www.buildeck.co.uk'?'UA-47087919-15':null,
    pageTitleBase: 'BuilDeck',
    assetBase: 'https://appzuka-web.s3.eu-west-2.amazonaws.com/buildeck/imagestream/',
    fullpixlist: 'fullpixlist.json',
    index: CommitInfo.index,
    siteName: 'www.buildeck.co.uk',
    highlight: '#68b0e0',
    pageLabels: {
        '/about': 'about us'
    },
    cognitoConfig: {
        region: 'eu-west-1',
        clientID: '28toug1d6udh9ipmk1cbejd07q',
        userpool: 'eu-west-1_ktnvCHUat',
        identityPool: 'eu-west-1:72ed9f40-bf99-4692-960d-818c47b16351'
    }
}

export const PrivacyContacts = {
    company: 'BuilDeck',
    policyDate: 'Mar 2021',
    contact: 'info@buildeck.co.uk'
}

// export const assetBase = 'https://appzuka-web.s3.eu-west-2.amazonaws.com/appzuka/content/';



